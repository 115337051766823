import React from "react"
import PropTypes from "prop-types"

const Banner = ({ content }) => {
  return (
    <h1>
      i'm a full-stack developer <code>&&</code> solutions architect, currently working in
      serverless data-driven mobile & web apps using amazon web services,
      android (java & kotlin) and react.
    </h1>
  )
}

Banner.propsTypes = {
  content: PropTypes.string,
}

export default Banner
